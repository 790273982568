exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clients-index-tsx": () => import("./../../../src/pages/clients/index.tsx" /* webpackChunkName: "component---src-pages-clients-index-tsx" */),
  "component---src-pages-company-about-us-tsx": () => import("./../../../src/pages/company/about-us.tsx" /* webpackChunkName: "component---src-pages-company-about-us-tsx" */),
  "component---src-pages-company-board-of-directors-tsx": () => import("./../../../src/pages/company/board-of-directors.tsx" /* webpackChunkName: "component---src-pages-company-board-of-directors-tsx" */),
  "component---src-pages-company-our-team-tsx": () => import("./../../../src/pages/company/our-team.tsx" /* webpackChunkName: "component---src-pages-company-our-team-tsx" */),
  "component---src-pages-company-privacy-policy-tsx": () => import("./../../../src/pages/company/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-company-privacy-policy-tsx" */),
  "component---src-pages-company-terms-and-conditions-tsx": () => import("./../../../src/pages/company/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-company-terms-and-conditions-tsx" */),
  "component---src-pages-home-artificial-intelligence-tsx": () => import("./../../../src/pages/home/artificial-intelligence.tsx" /* webpackChunkName: "component---src-pages-home-artificial-intelligence-tsx" */),
  "component---src-pages-home-design-and-development-tsx": () => import("./../../../src/pages/home/design-and-development.tsx" /* webpackChunkName: "component---src-pages-home-design-and-development-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-blogs-tsx": () => import("./../../../src/pages/media/blogs.tsx" /* webpackChunkName: "component---src-pages-media-blogs-tsx" */),
  "component---src-pages-media-elastic-connections-tsx": () => import("./../../../src/pages/media/elastic-connections.tsx" /* webpackChunkName: "component---src-pages-media-elastic-connections-tsx" */),
  "component---src-pages-media-podcasts-tsx": () => import("./../../../src/pages/media/podcasts.tsx" /* webpackChunkName: "component---src-pages-media-podcasts-tsx" */),
  "component---src-pages-platforms-aws-tsx": () => import("./../../../src/pages/platforms/AWS.tsx" /* webpackChunkName: "component---src-pages-platforms-aws-tsx" */),
  "component---src-pages-platforms-microsoft-dynamics-tsx": () => import("./../../../src/pages/platforms/Microsoft-dynamics.tsx" /* webpackChunkName: "component---src-pages-platforms-microsoft-dynamics-tsx" */),
  "component---src-pages-platforms-salesforce-tsx": () => import("./../../../src/pages/platforms/Salesforce.tsx" /* webpackChunkName: "component---src-pages-platforms-salesforce-tsx" */),
  "component---src-pages-services-ai-consultancy-tsx": () => import("./../../../src/pages/services/ai-consultancy.tsx" /* webpackChunkName: "component---src-pages-services-ai-consultancy-tsx" */),
  "component---src-pages-services-blockchain-development-tsx": () => import("./../../../src/pages/services/blockchain-development.tsx" /* webpackChunkName: "component---src-pages-services-blockchain-development-tsx" */),
  "component---src-pages-services-data-analytics-tsx": () => import("./../../../src/pages/services/data-analytics.tsx" /* webpackChunkName: "component---src-pages-services-data-analytics-tsx" */),
  "component---src-pages-services-design-consultancy-tsx": () => import("./../../../src/pages/services/design-consultancy.tsx" /* webpackChunkName: "component---src-pages-services-design-consultancy-tsx" */),
  "component---src-pages-services-dev-ops-tsx": () => import("./../../../src/pages/services/dev-ops.tsx" /* webpackChunkName: "component---src-pages-services-dev-ops-tsx" */),
  "component---src-pages-services-ml-ops-tsx": () => import("./../../../src/pages/services/ml-ops.tsx" /* webpackChunkName: "component---src-pages-services-ml-ops-tsx" */),
  "component---src-pages-services-mobile-app-development-tsx": () => import("./../../../src/pages/services/mobile-app-development.tsx" /* webpackChunkName: "component---src-pages-services-mobile-app-development-tsx" */),
  "component---src-pages-services-mvp-design-tsx": () => import("./../../../src/pages/services/mvp-design.tsx" /* webpackChunkName: "component---src-pages-services-mvp-design-tsx" */),
  "component---src-pages-services-staff-augmentation-tsx": () => import("./../../../src/pages/services/staff-augmentation.tsx" /* webpackChunkName: "component---src-pages-services-staff-augmentation-tsx" */),
  "component---src-pages-services-team-as-a-service-tsx": () => import("./../../../src/pages/services/team-as-a-service.tsx" /* webpackChunkName: "component---src-pages-services-team-as-a-service-tsx" */),
  "component---src-pages-services-ui-ux-design-tsx": () => import("./../../../src/pages/services/ui-ux-design.tsx" /* webpackChunkName: "component---src-pages-services-ui-ux-design-tsx" */),
  "component---src-pages-services-web-development-tsx": () => import("./../../../src/pages/services/web-development.tsx" /* webpackChunkName: "component---src-pages-services-web-development-tsx" */),
  "component---src-pages-work-anmol-tsx": () => import("./../../../src/pages/work/anmol.tsx" /* webpackChunkName: "component---src-pages-work-anmol-tsx" */),
  "component---src-pages-work-autilent-tsx": () => import("./../../../src/pages/work/autilent.tsx" /* webpackChunkName: "component---src-pages-work-autilent-tsx" */),
  "component---src-pages-work-automated-meter-reading-tsx": () => import("./../../../src/pages/work/automated-meter-reading.tsx" /* webpackChunkName: "component---src-pages-work-automated-meter-reading-tsx" */),
  "component---src-pages-work-aynuk-tsx": () => import("./../../../src/pages/work/aynuk.tsx" /* webpackChunkName: "component---src-pages-work-aynuk-tsx" */),
  "component---src-pages-work-creator-verse-tsx": () => import("./../../../src/pages/work/creator-verse.tsx" /* webpackChunkName: "component---src-pages-work-creator-verse-tsx" */),
  "component---src-pages-work-crowd-analytics-tsx": () => import("./../../../src/pages/work/crowd-analytics.tsx" /* webpackChunkName: "component---src-pages-work-crowd-analytics-tsx" */),
  "component---src-pages-work-drug-toxicity-tsx": () => import("./../../../src/pages/work/drug-toxicity.tsx" /* webpackChunkName: "component---src-pages-work-drug-toxicity-tsx" */),
  "component---src-pages-work-face-recognition-tsx": () => import("./../../../src/pages/work/face-recognition.tsx" /* webpackChunkName: "component---src-pages-work-face-recognition-tsx" */),
  "component---src-pages-work-learning-plus-tsx": () => import("./../../../src/pages/work/learning-plus.tsx" /* webpackChunkName: "component---src-pages-work-learning-plus-tsx" */),
  "component---src-pages-work-license-plate-recognition-tsx": () => import("./../../../src/pages/work/license-plate-recognition.tsx" /* webpackChunkName: "component---src-pages-work-license-plate-recognition-tsx" */),
  "component---src-pages-work-object-detection-tsx": () => import("./../../../src/pages/work/object-detection.tsx" /* webpackChunkName: "component---src-pages-work-object-detection-tsx" */),
  "component---src-pages-work-pep-due-diligence-tsx": () => import("./../../../src/pages/work/pep-due-diligence.tsx" /* webpackChunkName: "component---src-pages-work-pep-due-diligence-tsx" */),
  "component---src-pages-work-shaed-tsx": () => import("./../../../src/pages/work/shaed.tsx" /* webpackChunkName: "component---src-pages-work-shaed-tsx" */),
  "component---src-pages-work-time-series-forecasting-tsx": () => import("./../../../src/pages/work/time-series-forecasting.tsx" /* webpackChunkName: "component---src-pages-work-time-series-forecasting-tsx" */),
  "component---src-pages-work-zak-app-tsx": () => import("./../../../src/pages/work/zak-app.tsx" /* webpackChunkName: "component---src-pages-work-zak-app-tsx" */)
}

